// Floaty forms where when you type into the box, the label looks like
// the placeholder, then shuffles up into the top of the field when
// the input field has content in it
.floating-placeholder--input {
  border: 1px solid $gray-light;
  height: 44px;
  padding: 0;
  width: 100%;
  border-radius: 5px;
  padding: 5px 10px 5px;
  font-size: 15px;
  &:not(:placeholder-shown) { padding: 17px 10px 5px }
  margin-bottom: 10px;
}
.floating-placeholder--label {
  color: gray;
  position: absolute;
  margin: -48px 0 0 11px;
  pointer-events: all;
  font-size: 15px;
  display: block;
  opacity: 0;
  visibility: hidden;
  @extend .body-e;
}
.floating-placeholder--input:not(:placeholder-shown) + .floating-placeholder--label {
  font-size: 12px;
  transition: .1s all ease-in-out;
  color: $gray;
  visibility: visible;
  opacity: .75;
}
.floating-form .button {
  width: 100%;
  margin-bottom: 15px;
  height: 44px;
  border-radius: 5px;
}

// For when it's just [Name] [Email] [Submit] with consent below it
// Put it in one line vs on top of each other
@media all and (min-width: 661px) {
  .floating-form.optin .field:nth-child(1) { // Name
    width: 30%;
    float: left;
    margin-right: 2%;
  }
  .floating-form.optin .field:nth-child(2) { // Email
    width: 40%;
    float: left;
    margin-right: 2%;
  }
  .floating-form.optin .field:nth-child(3) { // Submit
    width: 26%;
    float: right;
  }
}
