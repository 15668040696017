.form {
  // Field is text, check is checkboxes, etc.
  .field, .field-check, .field-radio { position: relative; @extend .body-e }

  // Label within the box
  .field label {
    display: block;
    position: absolute;
    top: 7px;
    left: 1px;
    pointer-events: none;
    color: lighten($gray, 5%);
    font-size: 14px;
    background: linear-gradient(90deg, rgba(255,255,255,1) 85%, rgba(255,255,255,0) 100%);
    width: 95px;
    padding: .25em 0 0 10px;
    height: 20px;
  }

   // Same as above but for tighter spaces
   // (Makes the field box shorter width-wise)
  .field.tight label { width: 55px }

  // Making check/radio fields look good
  .field-check, .field-radio {
    margin-bottom: 1.25em;
    label:first-child { @extend .head-e }
    
    label {
      padding: 0 0 .5em;
      display: block;
    }
  }

  // Consent messages below forms for GDPR etc.
  .field-consent {
    @extend .body-small;
    padding: .75em .5em .5em;
    background: $gold-white;
    padding-left: 2rem;
    position: relative;
    margin-top: .5em;
    margin-bottom: -20px;
    border-radius: 4px;

    .dark & {
      background: transparent;
      padding: .75em 0 .5em 1.5rem;
      a { color: inherit }
    }

    input { 
      position: absolute;
      left: .75em;
      top: 5px;
      .dark & { left: 0 }
    }
  }

  // Making fields themselves look good within the box
  input[type="text"],
  input[type="email"] {
    display: block;
    background: $white;
    border-radius: 4px;
    border: 1px solid #CCDCE1;
    padding: .5em 0 .5em 90px;
    text-indent: 10px;
    font-size: 14px;
    color: $gray-dark;
    letter-spacing: 0;
    height: 38px;
    margin-bottom: .5em;
    width: 100%;
    &:focus { border-color: $blue }
  }

  // Same as above but for tighter spaces
  .field.tight input[type="text"],
  .field-tight input[type="email"] { padding-left: 50px }

  ::placeholder { color: lighten($gray, 20%) }

  // Buttons within forms to be full-width and prominent
  .button {
    width: 100%;
    text-align: center;
    height: 41px;
  }

  .consent {
    padding-left: 25px;
    margin-top: .5em;
    position: relative;

    input {
      position: absolute;
      left: 0;
    }
  }


  // Form Progressions
  // For forms that have multiple steps in them
  .step {
    opacity: 0;
    overflow: hidden;
    height: 0;
    transition: .2s opacity ease-in-out;
  }

  // The active state in a progression
  .is-open {
    opacity: 1;
    overflow: none;
    height: auto;
  }


  // Security badge saying we won't spam you etc.
  .form-secure {
    padding: 0 2em 0 2em;

    p:before {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url('/images/global/form-secure.png') no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
    }
  }
}