// Panel

// If a panel is a link, make sure the whole thing doesn't get underlined
a.panel { text-decoration: none }

// Main Panel atom
.panel {
  display: block;
  background: $white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  margin-bottom: 1em;
  padding: 2em;

  &.dark, .dark & {
    background: rgba(255,255,255,.15);
    border: 1px solid rgba(255,255,255,.2);
  }

  &.dark-alt {
    background: rgba(0,0,0,.15);
    border: 1px solid rgba(0,0,0,.2);
  }
}

// For the top of a panel
.panel-title {
  position: relative;
  @extend .pre-c;
  background: $gray-white;
  padding: 1.25em 1em 0.75em 2.15em;
  margin: -2em -2em 2em;
  border-radius: $border-radius-inner $border-radius-inner 0 0;

  .dark & {
    background: rgba(255,255,255,.2);
    color: $gray-light;
  }
  .dark-alt & { background: rgba(0,0,0,.1) }

  // For if it's got a picture etc in it
  &.panel-title-author {
    line-height: 1.2;
    height: 55px;

    strong {
      font-weight: 500;
      color: $gray-dark;
    }

    img {
      float: left;
      margin: -1px 10px 0 0;
      border-radius: 100px;
    }
  }
}

// Larger background shadow
.panel.diffused { box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 40px 0 rgba(0, 0, 0, 0.06) }

// Putting a panel on its own in the center of the screen
// Retired in favour of a content-solo variation
.panel.solo {
  max-width: 500px;
  margin: 2em auto;
}

// For smaller areas, this brings in the padding
.panel.small { padding: 1em }

// Fills in the panel background to highlight as a special object
.panel.cta {
  //background: $gray-white;
  border: 8px solid white;
  padding: 1.6em;
}

.panel.padded {
  @media all and (min-width: 960px) { padding: 4.5em }
}

// For a coupon or something like that
.panel .code {
  background: rgba(#232B32, .1);
  border-radius: $border-radius-inner;
  padding: 1em 1em .9em;
  margin-top: 1.25em;
  @extend .body-e;
  font-size: 16px;
  color: #727D87;
  letter-spacing: 3.8px;
  text-align: center;
  line-height: 21px;
}

// Make the picture fit the space properly
.panel .feature {
  max-width: calc(100% + 3em);
  width: calc(100% + 3em);
  margin: -1em -1.5em 1.5em;
}




// For the top of a panel
.panel-title {
	position: relative;
  @extend .pre-c;
  background: $gray-white;
  padding: 1.25em 1em 0.75em 2.15em;
  margin: -2em -2em 2em;
  border-radius: $border-radius-inner $border-radius-inner 0 0;

  .dark & {
    background: rgba(255,255,255,.2);
    color: $gray-light;
  }
  .dark-alt & { background: rgba(0,0,0,.1) }
}

// Same as the regular title, just larger
.panel-title-large {
  @extend .head-d;
  background: $gray-white;
  border-radius: $border-radius-inner $border-radius-inner 0 0;
  padding: 1em .5em 0.75em 1.65em;
  margin: -1.5em -1.5em 1.5em;

  // Bring it in spacing-wise if the panel is small
  .panel.small & {
    padding: .75em .5em .5em .75em;
    margin: -.75em -.75em .75em;
  }

  .dark & {
    background: rgba(255,255,255,.2);
    color: $gray-light;
  }
  .dark-alt & { background: rgba(0,0,0,.1) }
}

// Closing element at the bottom of a panel
.panel-footer {
	position: relative;
  @extend .body-e;
  background: $gray-white;
  padding: 1em 1em 0.75em 2.15em;
  margin: 1.5em -1.6em -1.6em;
  border-radius: 0 0 $border-radius-inner $border-radius-inner;
  &:last-child { margin-bottom: -1.6em } // Lil' override

  // Keep link subtle in here
  a {
    color: $gray-light;
    text-decoration: none;
    &:hover { text-decoration: underline }
  }

  .dark & {
    background: rgba(255,255,255,.2);
    color: $gray-light;
  }
  @media (prefers-color-scheme: dark) {
    background: rgba(255,255,255,.2);
    color: $gray-light;
  }
  .dark-alt & { background: rgba(0,0,0,.1) }
}

// If the panel will have an icon in it, right-align at the top
.panel-icon {
  float: right;
  margin: -4.5px -4.5px 0 0;
}

// For if there's an image or video at the top of a panel
.panel-figure {
  margin: -2em -2em 2em -2em;
  max-width: calc(100% + 4em);
  border-radius: $border-radius-inner $border-radius-inner 0 0;
}

// For bullet slider stuff
.panel-control {
  @extend .pre-c;
  text-align: center;
  background: $gray-white;
  padding: 1.25em 1em 0.75em 2.15em;
  margin: 1em -2em -2em;
  border-radius: 0 0 $border-radius-inner $border-radius-inner;

  .dark & {
    background: rgba(255,255,255,.2);
    color: $gray-light;
  }
  .dark-alt & { background: rgba(0,0,0,.1) }
  
  a {
    background: rgba($gray-dark, .4);
    display: inline-block;
    border-radius: 100px;
    color: transparent;
    width: 10px;
    height: 10px;
    margin: 0 1px;
  }
  a.active { background: rgba($gray-dark, .9) }
}



.panel-product {
  @media all and (max-width: 959px) {
    text-align: center;
  }
  @media all and (min-width: 960px) {
    display: grid;
    grid-template-columns: 180px auto;
    grid-gap: 40px;
  }

  img {
    @media all and (min-width: 960px) {
      margin-left: -70px;
      max-width: 260px;
    }
    @media all and (max-width: 959px) {
      margin-bottom: 15px;
    }
  }
}



// Put an image to the left of the content in the panel
.panel.has-media {
  @media all and (max-width: 959px) {
    text-align: center;
  }
  @media all and (min-width: 960px) {
    display: grid;
    grid-template-columns: 240px auto;
    grid-gap: 40px;
  }

  img {
    @media all and (min-width: 960px) {
      max-width: 250px;
    }
    @media all and (max-width: 959px) {
      margin-bottom: 15px;
    }
  }
}

.panel.dotted {
  border: 2px dashed $gray-light;
  background: transparent;
  box-shadow: none;
  .content { opacity: .5 }
}