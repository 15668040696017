#lunrsearchresults .post-panel { padding-bottom: 1rem }

.blog-search { min-height: 100px; padding: 0 2em; }

#lunrsearch {
  border: 1px solid $gray-light;
  height: 44px;
  border-radius: 5px;
  padding: 4px 10px 5px;
  font-size: 17px;
	display: block;
	background: $white;
  margin: 0 auto 10px;
	max-width: 600px;
	color: $gray;
	width: 100%;
  font-family: $sans-serif;
  text-decoration: none;
	&:focus { border-color: $blue; }
}

#lunrsearchresults > p {
	text-align: center;
	margin-bottom: 0;
}

// For a 'no results shown' box in categories or search
.blog-nada {
  text-align: center;
  margin: 0 auto;

  @media all and (min-width: 761px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media all and (min-width: 960px) {
    grid-column-start: 2;
    grid-column-end: 2;
  }
}


// Grid of blog posts

.blog-grid {
  padding: 2em 0;
}

.blog-grid .container {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 0%;

  @media all and (min-width: 761px) {
    grid-template-columns: 47% 47%;
    grid-gap: 0% 6%;
  }

  @media all and (min-width: 960px) {
    grid-template-columns: 31% 31% 31%;
    grid-gap: 0% 3.5%;
  }
}


// These are for panels in grids where the alignments are all a bit different
.post-panel {
  display: block;
  border-radius: 9px;
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.07);
  background: white;
  padding-bottom: .5rem;
  text-decoration: none;
  margin-bottom: 1em;
  transition: .1s all ease-in-out;
  padding-bottom: 4rem;
  position: relative;

  @media (prefers-color-scheme: dark) {
    background: rgba(255,255,255,.15);
    border: 1px solid rgba(255,255,255,.2);
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 16px 30px 0 rgba(0, 0, 0, 0.14);
  }

  .feature {
    border-radius: $border-radius $border-radius 0 0;
    width: 100%;
  }

  h2 {
    @extend .nw-head-e;
    padding: 1.5rem 1.5rem 0;
  }

  .excerpt {
    @extend .nw-body-d;
    padding: 0 1.5rem .75rem;
  }

  aside {
    margin: 0 .5rem;
    border-radius: 0 0 $border-radius-inner $border-radius-inner;
    position: absolute;
    bottom: .5rem;
    left: 0;
    right: 0;
  }
}