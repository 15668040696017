// Hero image on the front page & in subscribe boxes
figure.hero {
  float: left;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;

  .subscribe.on-subscribe-page & img { margin:10px auto 10px }
  .subscribe & img { margin: 0 auto 10px }
}

// Add an arrow in the hero section
.floating-form.optin { position: relative }
.floating-form.optin:before {
  content: '';
  display: block;
  width: 18px;
  height: 300px;
  background: url('/images/afdp/hero-arrow.svg') no-repeat;
  background-size: contain;
  position: absolute;
  right: -10px;
  top: -38px;
}

// iPhone X+ hero header on one line
@media all and (max-width: 500px) {
  .blog-show .blog-header .head-super {
    margin-left: -20px;
    margin-right: -20px;
  }
}

// POI on homepage
.blog-header .content-solo .poi {
  margin: 1em auto -1.5em;
  @media all and (min-width: 650px) { margin: 1em auto -1.5em }
}

.blog-header .nw-head-a {
  margin: 40px auto 20px
}