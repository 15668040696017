// Article content


.blog-post {
	padding-top: 5em;
}


.post {
	max-width: 650px;
	margin: 0 auto;
	padding: 0 2em 3em;

  a {
    color: $blue-dark;
    &:hover { color: $red }
  }


	// Header section

	header { margin: 4em 0 2em }

	h1 strong { @extend .head-a }

	h1 span {
		@extend .body-b;
		display: block;
		margin-top: .5em;
	}

	h2 {
		@extend .head-b;
		margin-top: 1em;
		border-top: 3px solid $gray-light;
		padding-top: 1em;
	}

	h3,h4 {
		@extend .head-c;
		margin-top: 1.5em;
	}

	h5,h6 { @extend .head-d }


	// Article content
	> p,ul,ol  { @extend .body-a }

  blockquote { margin-bottom: 1em }
  blockquote p { @extend .body-f }

  ul > li:before {
    content: '\25C6';
    position: absolute;
    margin-left: -2em;
    color: $blue;
  }

  ol {
    counter-reset: ol-counter;
  }

  ol > li:before {
    position: absolute;
    margin-left: -3em;
    background: $blue-dark;
    color: white;
    content: counter(ol-counter);
    counter-increment: ol-counter;
    width: 21px;
    height: 21px;
    border-radius: 100px;
    font-size: .8rem;
    font-weight: 500;
    text-align: center;
    line-height: 1.9;
  }

}