// Scaffolding

.container {
  max-width: 770px;
  margin: 0 auto;
  padding: 0 2em;

  @media all and (min-width: 960px) {
    max-width: 960px;
  }
}

.content-solo a,
.content-solo-large a,
.content-solo-xlarge a,
.content-solo-small a { color: $blue }
.content-solo h2 a {
  color: $gray-dark;
  text-decoration: none;
}

// Center a content element if it's going to be all on its lonesome
.content-solo-small {
  max-width: 585px;
  margin: 0 auto;
}

// The standard width version
.content-solo {
  max-width: 650px;
  margin: 0 auto;
}

// The same as the one above, just a smidge wider
.content-solo-large {
  max-width: 760px;
  margin: 0 auto;
}

// If content is to the right (with visuals left), use this
.content-right {
  @media all and (min-width: 761px) {
		max-width: 50%;
		margin-left: 50%;
	}
}

// Budge up a bit!
.push-top { margin-top: 1.5em }

.right { float: right }

.rounded { border-radius: $border-radius-inner }

.block { margin-bottom: 15px }

.hide-on-small { 
  @media all and (max-width: 759px) { display: none }
}