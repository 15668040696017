.under-scene { margin-top: 840px }

@media all and (max-width: 650px) { 
  .under-scene { margin-top: 300px }
  .focus-intro .under-scene { margin-top: 420px }
}

body.focus-adam .dry,
body.focus-adam .global-closing,
body.focus-mortiverse .dry,
body.focus-mortiverse .global-closing,
body.focus-fc .dry,
body.focus-fc .global-closing,
body.focus-lkrv .dry,
body.focus-lkrv .global-closing,
body.focus-ud .dry,
body.focus-ud .global-closing { display: none }


.dialog {
  transform: scale(1);
  opacity: 1;
  transition: .2s all ease-in-out;
  visibility: visible;
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  top: 530px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 600px;
  background: #75c6c6;
  border: 5px solid #75c6c6;
  border-radius: 15px;
  padding: 20px 35px;
  box-shadow: 0 4px 7px rgba(0,0,0,0.15);
}
.dialog:before {
  content: '';
  display: block;
  position: absolute;
  left: 5px;
  top: -5px;
  bottom: -5px;
  height: auto;
  width: 30px;
  background: url('/images/scene/dialog-af-corner-tl.png'),
              url('/images/scene/dialog-af-corner-tr.png'),
              url('/images/scene/dialog-af-corner-br.png'),
              url('/images/scene/dialog-af-corner-bl.png'),
              linear-gradient(0deg, #f2fdfd, white);
  background-repeat:   no-repeat,
                       no-repeat,
                       no-repeat,
                       no-repeat;
  background-position: top left,
                       top right,
                       bottom right,
                       bottom left;
  border: 5px solid #75c6c6;
  border-radius: 15px;
  right: 5px;
  width: auto;
}
.dialog.is-hidden {
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
}
.dialog .contents {
  position: relative;
}
.dialog .contents p {
  font: normal normal 300 20px/28px rooney-web, serif;
  letter-spacing: -0.5px;
  color: #2C3745;
  margin: 7px 0 15px;
}

.dialog-options a {
  font: normal normal 600 18px/18px rooney-sans, sans-serif;
  color: #2C3745;
  background: #c9edef;
  border-radius: 10px;
  text-decoration: none;
  padding: 10px;
  margin: 5px 15px 5px 0;
  display: inline-block;
}
.dialog_af .dialog-options a { color: #2a6464; }
.dialog_mv .dialog-options a { color: #78272d; }
.dialog_lkrv .dialog-options a { color: #367254; }
.dialog_ud .dialog-options a { color: #8b3f11; }
.dialog_fc .dialog-options a { color: #57317a; }

.dialog-options a:hover {
  background: #2C3745;
  color: white;
}

.dialog-name {
  font: italic normal 900 25px/35px rooney-web, serif;
  letter-spacing: -1px;
  color: white;
  margin-bottom: 25px;
  background: #75c6c6;
  display: inline-block;
  border-radius: 10px;
  padding: 2px 11px 3px;
  position: absolute;
  top: -30px;
  left: 28px;
}

.close {
  font: normal normal 500 25px/27px sans-serif;
  background: #2C3745;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 100px;
  position: absolute;
  top: -15px;
  right: -13px;
}

.dialog_intro {
  top: 80px;
  padding: 10px 35px 4px;
}

.dialog_mv   { background: #ca6b72; border-color: #ca6b72 }
.dialog_lkrv { background: #75c69d; border-color: #75c69d }
.dialog_ud { background: #fb853c; border-color: #fb853c }
.dialog_fc { background: #a783c8; border-color: #a783c8 }
.dialog_mv:before   { border-color: #ca6b72 }
.dialog_lkrv:before { border-color: #75c69d }
.dialog_ud:before { border-color: #fb853c }
.dialog_fc:before { border-color: #a783c8 }
.dialog_mv   .dialog-options a { background: #f1e1e3 }
.dialog_lkrv .dialog-options a { background: #75c69d40 }
.dialog_ud   .dialog-options a { background: #fb853c40 }
.dialog_fc   .dialog-options a { background: #a783c840 }
.dialog_mv   .dialog-options a:hover { background: #ca6b72 }
.dialog_lkrv .dialog-options a:hover { background: #75c69d }
.dialog_ud   .dialog-options a:hover { background: #fb853c }
.dialog_fc   .dialog-options a:hover { background: #a783c8 }
.dialog_mv   .dialog-name { background: #ca6b72 }
.dialog_lkrv .dialog-name { background: #75c69d }
.dialog_ud   .dialog-name { background: #fb853c }
.dialog_fc   .dialog-name { background: #a783c8 }

.dialog_mv:before {
  background-image: url('/images/scene/dialog-mv-corner-tl.png'),
              url('/images/scene/dialog-mv-corner-tr.png'),
              url('/images/scene/dialog-mv-corner-br.png'),
              url('/images/scene/dialog-mv-corner-bl.png'),
              linear-gradient(0deg, #f7f1f1, white);
  background-repeat:   no-repeat,
                       no-repeat,
                       no-repeat,
                       no-repeat;
  background-position: top left,
                       top right,
                       bottom right,
                       bottom left;
}

.dialog_lkrv:before {
  background-image: url('/images/scene/dialog-lkrv-corner-tl.png'),
              url('/images/scene/dialog-lkrv-corner-tr.png'),
              url('/images/scene/dialog-lkrv-corner-br.png'),
              url('/images/scene/dialog-lkrv-corner-bl.png'),
              linear-gradient(0deg, #e7fff3, white);
  background-repeat:   no-repeat,
                       no-repeat,
                       no-repeat,
                       no-repeat;
  background-position: top left,
                       top right,
                       bottom right,
                       bottom left;
}

.dialog_ud:before {
  background-image: url('/images/scene/dialog-ud-corner-tl.png'),
              url('/images/scene/dialog-ud-corner-tr.png'),
              url('/images/scene/dialog-ud-corner-br.png'),
              url('/images/scene/dialog-ud-corner-bl.png'),
              linear-gradient(0deg, #f5e7de, white);
  background-repeat:   no-repeat,
                       no-repeat,
                       no-repeat,
                       no-repeat;
  background-position: top left,
                       top right,
                       bottom right,
                       bottom left;
}

.dialog_fc:before {
  background-image: url('/images/scene/dialog-fc-corner-tl.png'),
              url('/images/scene/dialog-fc-corner-tr.png'),
              url('/images/scene/dialog-fc-corner-br.png'),
              url('/images/scene/dialog-fc-corner-bl.png'),
              linear-gradient(0deg, #eee6f5, white);
  background-repeat:   no-repeat,
                       no-repeat,
                       no-repeat,
                       no-repeat;
  background-position: top left,
                       top right,
                       bottom right,
                       bottom left;
}


.scene_1, .scene_2, .scene_3, .scene_4, .scene_5, .scene_6 {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 1100px;
  height: 800px;
}

.scene_1 {
  background: url('/images/scene/af-bg.png') no-repeat;
  background-size: contain;
}
.scene_1 > a,
.scene_1 > div {
  display: block;
  position: absolute;
  text-overflow: hidden;
  text-align: left;
  text-indent: -999em;
}

.scene_1-af:before,
.scene_1-mv:before,
.scene_1-lkrv:before,
.scene_1-ud:before,
.scene_1-fc:before {
  content: '';
  display: block;
  position: absolute;
  width: 160px;
  height: 65px;
  padding-top: 15px;
  text-indent: 0;
  font: normal normal 600 15px/16px rooney-sans, sans-serif;
  text-align: center;
  background: url('/images/scene/arrow-box.png') center bottom no-repeat;
  background-size: 160px auto;
  top: -90px;
  opacity: 0;
  transition: .2s all ease-in-out;
  white-space: pre;
  color: #2C3745;
  
  background: white;
  box-shadow: 0 4px 5px rgba(0,0,0,.25);
  border-radius: 10px;
  height: auto;
  padding: 10px 15px;
  width: auto;
  border-bottom: 1px solid rgba(0,0,0,.4);
}

.scene_1-af:after,
.scene_1-mv:after,
.scene_1-lkrv:after,
.scene_1-ud:after,
.scene_1-fc:after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background: white;
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  top: -40px;
  transition: .2s all ease-in-out;
  transform: rotate(45deg);
  border-right: 1px solid rgba(0,0,0,.4);
  border-bottom: 1px solid rgba(0,0,0,.4);
}
.scene_1-af:hover:after,
.scene_1-mv:hover:after,
.scene_1-lkrv:hover:after,
.scene_1-ud:hover:after,
.scene_1-fc:hover:after {
  top: -35px;
  opacity: 1;
}

.scene_1-af:before { left: -5px; }
.scene_1-mv:before { left: -40px; }
.scene_1-mv:after { left: -30px; }
.scene_1-lkrv:before { left: 160px; margin-top: 50px; }
.scene_1-fc:before { left: -26px }
.scene_1-ud:before { left: 15px }
.scene_1-ud:after { left: 60px }
.scene_1-af:hover:before,
.scene_1-mv:hover:before,
.scene_1-ud:hover:before,
.scene_1-fc:hover:before {
  opacity: 1;
  top: -80px;
}

.scene_1-af:before { content: 'Chat with \a Adam' }
.scene_1-mv:before { content: 'Chat about \a Memento Morti!' }
.scene_1-ud:before { content: 'Chat about \a Marketer From Space' }
.scene_1-fc:before { content: 'Chat about \a the studio' }

.scene_1-af {
  top: 212px;
  left: 100px;
  right: 100px;
  margin: 0 auto;
  width: 89px;
  height: 154px;
  background: url('/images/scene/af_scene-af/af_scene-af.png') no-repeat;
  background-size: 89px 154px;
}
.scene_1-mv {
  top: 271px;
  right: 318px;
  width: 100px;
  height: 160px;
  background: url('/images/scene/af_scene-ud-kezia/af_scene-ud-kezia.png') no-repeat;
  background-size: 95px 156px;
}
.scene_1-lkrv {
  top: 430px;
  left: 244px;
  width: 276px;
  height: 221px;
  background: url("/images/scene/af_scene-philosopher/af_scene-dd.png") 50px center no-repeat;
  background-size: 154px 118px;
}
.scene_1-lkrv:before {
  content: '';
  display: block;
  position: absolute;
  top: 187px;
  left: 94px;
  width: 233px;
  height: 45px;
  background: url('/images/scene/af_scene-philosopher/philover.png') no-repeat;
  background-size: contain;
}
.scene_1-ud {
  top: 304px;
  left: 296px;
  width: 140px;
  height: 180px;
  background: url('/images/scene/af_scene-ud-sedg/af_scene-ud-sedg.png') no-repeat;
  background-size: 133px 176px;
}
.scene_1-udtv {
  top: 298px;
  left: 201px;
  width: 172px;
  height: 121px;
  background: url('/images/scene/af_scene-ud-tvshow/af_scene-ud-tvshow.png') no-repeat;
  background-size: contain;
}
.scene_1-fc {
  top: 431px;
  right: 412px;
  width: 60px;
  height: 142px;
  background: url('/images/scene/af_scene-kat/af_scene-kat.png') no-repeat;
  background-size: contain;
}
.scene_1-holos-1,
.scene_1-holos-2,
.scene_1-holos-3 {
  width: 83px;
  height: 66px;
  background: url('/images/scene/af_scene-holo/af_scene-holo.png') no-repeat;
  background-size: contain;
  pointer-events: none;
}
.scene_1-holos-1 {
  top: 515px;
  left: 640px;
}
.scene_1-holos-2 {
  top: 490px;
  left: 708px;
}
.scene_1-holos-3 {
  top: 461px;
  left: 775px;
}

.scene_2 {
  background: url('/images/scene/af2.png') no-repeat;
  background-size: cover;
  opacity: 0;
  pointer-events: none;
  transition: .2s all ease-in-out;
}
body.focus-adam .scene_2 { opacity: 1 }

.scene_3 {
  background: url('/images/scene/af3.png') no-repeat;
  background-size: cover;
  opacity: 0;
  pointer-events: none;
  transition: .2s all ease-in-out;
}
body.focus-mortiverse .scene_3 { opacity: 1 }

.scene_4 {
  background: url('/images/scene/af4.png') no-repeat;
  background-size: cover;
  opacity: 0;
  pointer-events: none;
  transition: .2s all ease-in-out;
}
body.focus-lkrv .scene_4 { opacity: 1 }

.scene_5 {
  background: url('/images/scene/af5.png') no-repeat;
  background-size: cover;
  opacity: 0;
  pointer-events: none;
  transition: .2s all ease-in-out;
}
body.focus-ud .scene_5 { opacity: 1 }

.scene_6 {
  background: url('/images/scene/af6.png') no-repeat;
  background-size: cover;
  opacity: 0;
  pointer-events: none;
  transition: .2s all ease-in-out;
}
body.focus-fc .scene_6 { opacity: 1 }




// Mobile

@media all and (max-width: 650px) { 
  .scene_1 { 
    width: 100%;
    max-width: 380px;
  }
  .scene_1-af {
    transform: scale(0.35);
    top: 23px;
  }
  .scene_1-mv {
    transform: scale(0.35);
    top: 40px;
    right: 79px;
  }
  .scene_1-udtv {
    transform: scale(0.35);
    top: 63px;
    left: 13px;
  }
  .scene_1-ud {
    transform: scale(0.35);
    top: 44px;
    left: 58px;
  }
  .scene_1-fc {
    transform: scale(0.37);
    top: 100px;
    right: 124px;
  }
  .scene_1-lkrv {
    transform: scale(0.35);
    top: 76px;
    left: -6px;
  }
  .scene_1-holos-1 {
    transform: scale(0.35);
    top: 155px;
    left: 194px;
  }
  .scene_1-holos-2 {
    transform: scale(0.35);
    top: 146px;
    left: 218px;
  }
  .scene_1-holos-3 {
    transform: scale(0.35);
    top: 136px;
    left: 241px;
  }
  .scene_2,
  .scene_3,
  .scene_4,
  .scene_5,
  .scene_6 { 
    max-width: 380px;
    background-size: contain;
  }
  .dialog {
    max-width: 370px;
    top: 350px;
  }
}
