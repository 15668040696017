$sans-serif: 'rooney-sans';
$serif: 'rooney-web';

$white: white;
$black: #050F18;

$gray-white: #F8FAFB;
$gray-light: #DEDEDE;
$gray: #4E585F;
$gray-dark: #2c3745;
$gray-black: #232b32;

$blue-white: #D3F1FB;
$blue-light: #84E0FF;
$blue: #0CA5C1;
$blue-dark: #279ab8;
$blue-black: #1F4051;

$gold-white: #F7F6F6;
$gold-light: #FFF6D9;
$gold: #FFDB72;
$gold-dark: #A07B44;

$red-white: #F8E0E0;
$red-light: #ffe2da;
$red: #E4400F;
$red-dark: #890023;

$teal-white: #E2FFFA;
$teal-light: #B1E8E0;
$teal: #31D6BE;
$teal-dark: #006672;
$teal-black: #1C3033;


$border-radius: 9px;
$border-radius-inner: 7px;
$box-shadow: 0 3px 2px 0 rgba(0,0,0,0.05), 0 2px 8px 0 rgba(0,0,0,0.15);

$primary: $blue; // Primary link colour
