// General typography styles


// Making a cool-looking $ :)
.amp {
  font-family: "Baskerville", serif;
  font-style: italic;
}

// Break a line on desktop for special widths
.br-large { @media all and (min-width: 761px) { display: block } }

// To center text
.text-center { text-align: center }
.text-right { text-align: right }

code {
  display: block;
  background: $gray-white;
  padding: 1em;
  border-radius: 8px;
  margin: -15px 0 1.5em;
  font-family: source-code-pro, monospace;
  color: $gray;
  line-height: 1.4;
  font-weight: 400;
  font-style: normal;
}



// Typography 1: Studio

.pre-a {
  font-family: $serif;
  font-size: 1.3rem;
  color: $gray;
  letter-spacing: -0.7px;
  line-height: 1.1;
  margin-bottom: .45em;

  &.dark, .dark & { color: white }
  @media all and (min-width: 540px) { font-size: 1.4rem }
  @media all and (min-width: 761px) { font-size: 1.6rem }
  @media all and (min-width: 960px) { font-size: 1.8rem }
}

.pre-b {
  font-family: $serif;
  font-size: 20px;
  color: $gray;
  letter-spacing: -0.7px;
  line-height: 1.1;
  margin-bottom: 1em;

  &.dark, .dark & { color: $gray-light }
  @media all and (min-width: 761px) { font-size: 21px }
}

.pre-c {
  font-family: $sans-serif;
  text-transform: uppercase;
  font-size: .75rem;
  color: #727D87;
  letter-spacing: 0.65px;
  margin-bottom: 1em;

  &.dark, .dark & { color: $gray-light }
}

.head-super {
  font-family: $sans-serif;
  font-weight: 900;
  font-size: 39px;
  color: $gray-dark;
  letter-spacing: -2px;
  line-height: 0.88;
	margin-bottom: .35em;

  &.dark {
    color: $white;
  }

  @media all and (min-width: 540px) {
    font-size: 55px;
    letter-spacing: -3px;
  }
  @media all and (min-width: 960px) {
    font-size: 70px;
    letter-spacing: -4.6px;
  }
}

.head-a {
  font-family: $sans-serif;
  font-size: 40px;
  color: $gray-dark;
  letter-spacing: -2.8px;
  line-height: 1;
  font-weight: 800;
  margin-bottom: 0.6em;

  &.dark, .dark & { color: white }
	@media all and (min-width: 761px) { font-size: 46px }
}

.head-b {
  font-family: $sans-serif;
  font-weight: 800;
  font-size: 25px;
  color: $gray-dark;
  letter-spacing: -1.14px;
  line-height: 1.05;
  margin-bottom: 0.5em;
  display: block;

  &.dark, .dark & { color: white }
  @media all and (min-width: 500px) { font-size: 30px }
  @media all and (min-width: 761px) {
    font-size: 36px;
    letter-spacing: -1.6px;
  }
}

.head-c {
  font-family: $sans-serif;
  font-size: 20px;
  color: $black;
  letter-spacing: -0.7px;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: .5em;

  &.dark, .dark & { color: white }
  @media all and (min-width: 761px) {
    font-size: 20px;
    line-height: 27px;
  }
}

.head-d {
  font-family: $sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: $black;
  letter-spacing: -0.2px;
  line-height: 1.3;
  margin: .5em 0;

  &.dark, .dark & { color: white }
}

.head-e {
  font-family: $sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: $black;
  letter-spacing: -0.2px;
  line-height: 1.3;
  margin: 0 0 .15em;

  &.dark, .dark & { color: white }
}

.body-a {
  font-family: $sans-serif;
  color: $gray-dark;
  font-size: 1rem;
  letter-spacing: -0.26px;
  line-height: 1.5;
  margin-bottom: 1em;
  font-weight: 300;
  &:last-of-type { margin-bottom: 0 }

  strong {
    color: $black;
    font-weight: 500;
  }

  > li {
    padding-left: 3em;
    margin-bottom: .5em;
    &:last-child { margin-bottom: 1em; }

    &:before {
      content: '\25C6';
      position: absolute;
      margin-left: -1.5em;
      color: $blue;
      
      .dark & { color: white }
    }
  }

  // For if there's a list within a list
  > li li:first-child { margin-top: .5em }

  &.dark, .dark & { color: white }

	@media all and (min-width: 761px) {
		font-size: 1.2rem;
		line-height: 1.5;
	}
}

.body-b {
  font-family: $sans-serif;
  color: $gray;
  font-weight: 500;
  font-size: 19px;
  letter-spacing: -0.55px;
  line-height: 1.2;
  margin-bottom: 1em;
  &:last-child { margin-bottom: 0 }

  &.dark, .dark & { color: white }
  @media all and (min-width: 761px) { font-size: 22px }
  @media all and (min-width: 960px) { font-size: 24px }
}

.body-c {
  font-family: $sans-serif;
  font-size: 18px;
  color: $gray;
  letter-spacing: -0.3px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 1.25em;
  &:last-child { margin-bottom: 0 }

  strong {
    color: $gray-dark;
		font-weight: 600;
  }

  &.dark, .dark & {
    color: rgba($gray-white,.85);
    strong { color: white }
  }

  > li {
    padding-left: 1.5em;
    margin-bottom: .65em;
    &:last-child { margin-bottom: 0; }

    &:before {
      content: '\25C6';
      position: absolute;
      margin-left: -1.5em;
      color: $blue;
    }
  }

	@media all and (min-width: 761px) { font-size: 21px }
}

.body-d {
  font-family: $sans-serif;
  font-size: 1rem;
  color: $gray-dark;
  letter-spacing: -0.22px;
  line-height: 20px;
  margin-bottom: .75em;
  &:last-child { margin-bottom: 0 }

  strong {
    color: $black;
    font-weight: 500;
  }

  > li {
    padding-left: 2em;
    margin-bottom: .75em;
    &:last-child { margin-bottom: 0; }

    &:before {
      content: '\25C6';
      position: absolute;
      margin-left: -2em;
      color: $blue;
    }
  }

  &.dark, .dark & {
    color: $gray-light;
    strong { color: $gray-white; }
  }
}
ul.body-d,ol.body-d { &:last-of-type { margin-bottom: 1em; }}

.body-e {
  font-family: $sans-serif;
  font-size: .9rem;
  color: $gray;
  letter-spacing: -0.15px;
  line-height: 1.45;
  margin-bottom: .5em;
  &:last-child { margin-bottom: 0; }
  a { color: $primary }

  > li {
    padding-left: 1.5em;
    margin-bottom: .5em;
    &:last-child { margin-bottom: 0; }

    &:before {
      content: '\25C6';
      position: absolute;
      margin-left: -1.5em;
      color: $blue;
    }
  }

  &.dark, .dark & { color: $gray-light }
}

.body-f {
  font-family: $serif;
  font-size: 1.2rem;
  color: $gray-black;
  letter-spacing: -0.26px;
  line-height: 1.55;
  padding-left: 1em;
  margin-bottom: 1em;
  border-left: 4px solid $blue;

  &.dark, .dark & { color: $gray-light }
}

.body-g {
  font-family: $serif;
  font-size: 30px;
  line-height: 1.18;
  letter-spacing: -0.53px;
  text-align: center;
  color: $gray-dark;
  margin-bottom: .5em;

  &.dark, .dark & { color: $gray-light }
  @media all and (min-width: 761px) { font-size: 39px }
}

.body-small {
  font-family: $sans-serif;
  font-size: .75rem;
  color: $gray;
  letter-spacing: -0.15px;
  line-height: 1.25;
  margin-bottom: .5em;
  padding-left: 25px;
  position: relative;
  &:last-child { margin-bottom: 0; }

  &.dark, .dark & { color: $gray-light }
}



// Typography 2: Workshop

.nw-head-super {
  font: italic normal 900 84px/79px rooney-web, serif; 
  letter-spacing: -2.94px;
  color: #152323;
  margin-bottom: 35px;

  @media all and (max-width: 959px) {
    font: italic normal 900 74px/69px rooney-web, serif; 
    margin-bottom: 25px;
  }
}

.nw-head-a {
  font: italic normal 900 66px/60px rooney-web, serif;
  letter-spacing: -2.31px;
  color: #152323;
  margin-bottom: 35px;

  .dark & { color: white }

  @media all and (max-width: 959px) {
    font: italic normal 900 45px/48px rooney-web, serif;
    margin-bottom: 25px;
  }
}

.nw-head-a-sans {
  font: normal normal 900 66px/60px rooney-sans, serif;
  letter-spacing: -2.31px;
  color: #152323;
  margin-bottom: 35px;

  .dark & { color: white }

  @media all and (max-width: 959px) {
    font: italic normal 900 45px/48px rooney-web, serif;
    margin-bottom: 25px;
  }
}

.nw-head-b {
  font: italic normal 900 53px/55px rooney-web, serif;
  letter-spacing: -1.85px;
  color: #152323;
  margin-bottom: 35px;

  @media all and (max-width: 959px) {
    font: italic normal 900 35px/40px rooney-web, serif;
    margin-bottom: 25px;
  }
}

.nw-head-c {
  font: italic normal 900 39px/45px rooney-web, serif;
  letter-spacing: -1.17px;
  color: #2C3745;
  margin-bottom: 25px;

  @media all and (max-width: 959px) {
    font: italic normal 900 34px/37px rooney-web, serif
  }
}

.nw-head-c-sans {
  font: normal normal 900 39px/45px rooney-sans, serif;
  letter-spacing: -1.17px;
  color: #2C3745;
  margin-bottom: 25px;

  @media all and (max-width: 959px) {
    font: normal normal 900 34px/37px rooney-sans, serif
  }
}

.nw-head-d {
  font: italic normal 900 29px/35px rooney-web, serif;
  letter-spacing: -0.87px;
  color: #2C3745;
  margin-bottom: 10px;
  
  @media all and (max-width: 959px) {
    font: italic normal 900 24px/29px rooney-web, serif;
    margin-bottom: 10px;
  }
}

.nw-head-d-sans {
  font: normal normal 700 29px/35px rooney-sans, sans-serif;
  letter-spacing: -0.87px;
  color: #2C3745;
  margin-bottom: 10px;
  
  @media all and (max-width: 959px) {
    font: normal normal 700 24px/29px rooney-sans, sans-serif;
    margin-bottom: 10px;
  }
}

.nw-head-e {
  font: italic normal 900 21px/25px rooney-web, serif;
  letter-spacing: -0.63px;
  color: #2C3745;
  margin-bottom: 10px;

  @media all and (max-width: 959px) {
    font: italic normal 900 18.5px/24px rooney-web, serif
  }
}

.nw-head-e-sans {
  font: normal normal 700 21px/25px rooney-sans, serif;
  letter-spacing: -0.63px;
  color: #2C3745;
  margin-bottom: 10px;

  @media all and (max-width: 959px) {
    font: normal normal 700 18.5px/24px rooney-sans, serif
  }
}

.nw-body-a {
  font: normal normal 300 22px/30px rooney-web, serif;
  letter-spacing: -0.72px;
  color: #2C3745;
  margin-bottom: 20px;
  
  strong {
    font-style: italic;
    font-weight: 900;
  }

  @media all and (max-width: 959px) {
    font: normal normal 300 20px/30px rooney-web, serif;
    margin-bottom: 25px;
  }
}

.nw-body-b {
  font: normal normal 300 24px/32px rooney-web, serif;
  letter-spacing: -0.72px;
  color: #2C3745;
  margin-bottom: 35px;

  .dark & { color: rgba(255,255,255,.9) }
  
  strong {
    font-style: italic;
    font-weight: 900;
  }

  @media all and (max-width: 959px) {
    font: normal normal 300 20px/30px rooney-web, serif;
    margin-bottom: 25px;
  }
}

.nw-body-b-sans {
  font: normal normal 400 24px/32px rooney-sans, serif;
  letter-spacing: -0.72px;
  color: #2C3745;
  margin-bottom: 35px;

  .dark & { color: rgba(255,255,255,.9) }
  
  strong {
    font-style: italic;
    font-weight: 900;
  }

  @media all and (max-width: 959px) {
    font: normal normal 300 20px/30px rooney-sans, serif;
    margin-bottom: 25px;
  }
}

.nw-body-c, .nw-body-c p {
  font: normal normal 300 18px/26px rooney-web, serif;
  letter-spacing: -0.43px;
  color: #2C3745;
  margin-bottom: 15px;
  &:last-child { margin-bottom: 0 }
  strong { font-weight: 600 }

  > li {
    padding-left: 2em;
    margin-bottom: .75em;
    &:last-child { margin-bottom: 0; }

    &:before {
      content: '\25C6';
      position: absolute;
      margin-left: -2em;
      color: $blue;
    }
  }
}

.nw-body-d {
  font: normal normal normal 16px/23px rooney-web, serif;
  letter-spacing: -0.48px;
  color: #2C3745;
  margin-bottom: 18px;

  .dark & { color: rgba(255,255,255,.85) }

  li {
    list-style: disc inside;
    margin-bottom: 8px;
  }
}

.nw-note {
  font: normal normal normal 20px/30px ff-uberhand-pro, sans-serif;
  letter-spacing: -0.3px;
  color: #2C3745;
  margin-bottom: 25px;
  strong { font-weight: 700 }
}



// Typography 3: Comic

.note-comic {
  font: normal normal normal 24px/30px ff-uberhand-pro, sans-serif;
  letter-spacing: -0.18px;
  color: $gray-dark;
  margin-bottom: .6em;
  &:last-child { margin-bottom: 0 }
  strong { font-weight: 800 }
  @media all and (max-width: 800px) {
    font: normal normal normal 16px/19px ff-uberhand-pro, sans-serif;
  }
  .dark & { color: white }
}

.note-comic-head2 {
  font: bold normal normal 28px/30px ff-uberhand-pro, sans-serif;
  letter-spacing: -0.18px;
  color: $gray-dark;
  margin-bottom: .6em;
  &:last-child { margin-bottom: 0 }
  strong { font-weight: 800 }
  @media all and (max-width: 800px) {
    font: bold normal normal 18px/19px ff-uberhand-pro, sans-serif;
  }
  .dark & { color: white }
}

.note-comic-small {
  font: normal normal normal 19px/28px ff-uberhand-pro, sans-serif;
  letter-spacing: -0.18px;
  color: $gray-dark;
  margin-bottom: .6em;
  &:last-child { margin-bottom: 0 }
  strong { font-weight: 800 }
  @media all and (max-width: 800px) {
    font: normal normal normal 14px/19px ff-uberhand-pro, sans-serif;
  }
  .dark & { color: white }
}


hr {
  background: $gray-light;
  border: none;
  height: 1px;
  margin: 2em auto;
}
hr.light { 
  background: #F7F6F6;
  height: 2px;
}


// Type Systems

.type-system-a {
  .pre  { @extend .pre-a  }
  h1,h2 { @extend .head-a }
  h3    { @extend .head-b }
  h4    { @extend .head-c }
  h5,h6 { @extend .head-d }
  p:not(.pre), ul, ol { @extend .body-a }
}

.type-system-b {
  .pre { @extend .pre-c }
  h1,h2,h3,h4,h5,h6 { @extend .head-b }
  p:not(.pre), ul, ol { @extend .body-a }
}

.type-system-c {
  .pre { @extend .pre-b }
  h1,h2,h3,h4,h5,h6 { @extend .head-b }
  p:not(.pre), ul, ol { @extend .body-c }
}

.type-system-d {
  h1,h2,h3,h4,h5,h6 { @extend .head-c }
  p:not(.pre), ul, ol { @extend .body-d }
}

.type-system-e {
  h1,h2,h3,h4,h5,h6 { @extend .head-d }
  p:not(.pre), ul, ol { @extend .body-e }
}

.type-system-post {
  .pre { @extend .pre-c }
  h1 { @extend .nw-head-b }
  h2 { @extend .nw-head-d }
  h3,h4,h5,h6 { @extend .nw-head-d }
  p:not(.pre), ul, ol { @extend .nw-body-a }
}