// Accessible intro
.header-skip {
  clip: rect(1px,1px,1px,1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
a:focus-visible { outline: 2px dotted $blue !important }

// A bit of padding at the top so fixed headings don't eat the page
.fixed-nav-breaker { height: 66px }

// Section navigation

// Mobile darkening background when menu open
@media all and (max-width: 959px) {
  .section-nav { margin-left: -1px; margin-right: -1px }
  .mobile-menu-cover {
    opacity: 0;
  }
  body.mobile-menu-open .mobile-menu-cover {
    background: black;
    position: fixed;
    opacity: .5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: .2s opacity ease-in-out;
  }
}

.section-nav {
  padding-top: 1em;
  position: relative;
  z-index: 1;

  // Desktop only
  @media all and (min-width: 960px) {
    .links {
      float: right;

      li {
        display: inline-block;
        margin-left: .5em;
        @media all and (min-width: 661px) { margin-left: 1.25em }
      }
      .mobile-menu-close { display: none }
    }
    a.mobile-menu-open { display: none }
  }

  // Mobile only
  @media all and (max-width: 959px) {
    .links {
      background: white;
      position: fixed;
      top: 0;
      right: -350px;
      transition: .2s all ease-in-out;
      width: 300px;
      padding: 15px 0 0;
      border-radius: 8px 0 0 0;
      bottom: 0;
      height: 100%;
      box-shadow: 0 3px 30px 0 rgba(0,0,0,0.2), 0 2px 8px 0 rgba(0,0,0,0.15);

      .mobile-menu-open & { right: 0 }
      
      li a {
        display: block;
        padding: 10px 25px;
      }

      li .button {
        margin: 6px 25px 0;
        text-align: center;
      }

      .mobile-menu-close a {
        font-size: 39px;
        position: absolute;
        top: 0;
        right: 8px;
        padding: 0;
        width: 50px;
        text-align: center;
        padding: 19px 10px 0;
        line-height: 1;
        height: 50px;
      }
    }

    a.mobile-menu-open {
      font-family: $sans-serif;
      font-size: .9rem;
      color: $gray;
      letter-spacing: -0.15px;
      line-height: 1.45;
      background: #eee;
      padding: 10px 14px 7px;
      display: inline-block;
      border-radius: 7px;
      float: right;
      cursor: pointer;
      text-decoration: none;
    }
  }


  // Both
  @media all and (max-width: 660px) {
    .container {
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  .links {
    a:not(.button) {
      font: normal normal 400 14px/18px rooney-sans, sans-serif;
      color: $gray;
      text-decoration: none;

      &.active {
        font-weight: 600;
        color: $gray-dark;
        text-decoration: underline
      }

      &:hover { color: $black }
      .dark &:hover { color: white }
    }
  }
}

// Section nav changes for along the top for NW
.section-nav {
  background: rgba(255, 255, 255, 0.9) 0% 0% no-repeat padding-box;
  border: 1px solid #4E585F19;
  opacity: 1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: 68px;
  position: fixed;
  width: 100%;
  margin-top: -1px;
  z-index: 10;

  // For the NW logo
  .title { margin-top: 1px }
  .academy & .title { margin-top: -3px }

  .title.is-af { margin-top: -9px }
}



// Global Footer

.global-footer {
  background: $black;
  padding: 3em 0 2em;

  .container {
    display: grid;
    grid-template-columns: 47% 47%;
    grid-gap: 1% 6%;

    @media all and (min-width: 761px) {
      grid-template-columns: 25% 25% 25% 25%;
      grid-gap: 0%;
    }
  }

  ul { padding-bottom: 1em }

  strong {
    display: block;
    margin-bottom: .75rem;
  }

  li a {
    color: $gray-light;
    display: block;
    text-decoration: none;
    margin-bottom: .25rem;
  }
}

.global-closing {
  background: $gray-dark;

  .container {
    padding: 3em 2em;
    @media all and (max-width: 959px) { text-align: center }
  }

  a, div > div {
    background: rgba($gray, .25);
    border-radius: $border-radius-inner;
    text-align: left;
    padding: .8rem .8rem .5rem 3.75rem;
    background-repeat: no-repeat;
    background-position: .5rem;
    background-size: 44px;
    height: 52px;
    display: inline-block;
    @extend .body-e;
    color: rgba($gray-light, .75);
    font-size: .8rem;
    text-decoration: none;
    line-height: 1.2;
    margin-right: .5em;

    strong {
      color: white;
      font-weight: 500;
      display: block;
    }
  }

  .closing-gdpr { background-image: url('/images/global/closing-gdpr.svg') }
  .closing-cookie { background-image: url('/images/global/closing-cookie.svg') }
  .closing-cah {
    background-image: url('/images/global/closing-cah.svg');
    background-size: 39px;
    padding-left: 3.5rem;
  }
  .closing-bfi {
    background-image: url('/images/global/closing-bfi.svg');
    background-position: .75rem .8em;
    background-size: 28px;
    padding-left: 3rem;
  }

  .closing-logo {
    background: url('/images/global/closing-logo.png') center no-repeat;
    background-size: contain;
    width: 116px;
    height: 40px;
    @include retina { background-image: url('/images/global/closing-logo@2x.png') }

    &.miay {
      background: url('/images/miay-blog/closing-logo.png') center no-repeat;
      background-size: contain;
      width: 311px;
      @include retina { background-image: url('/images/miay-blog/closing-logo@2x.png') }
    }

    @media all and (max-width: 959px) { margin: 4em auto 0; }
    @media all and (max-width: 400px) { margin: 1em auto 0; }
    @media all and (min-width: 960px) {
      float: right;
      margin-top: 5px;
    }
  }

  &.dark { background-color: $gray-black }
}


.breadcrumb {
  font: normal normal 700 18px/35px rooney-sans, serif;
  letter-spacing: -0.63px;
  color: #2C3745;
  margin-bottom: 10px;
  color: $gray-light;
  margin-bottom: 0;

  a {
    text-decoration: none;
    color: $blue-dark;
  }
}