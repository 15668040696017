// Buttons

.button {
  display: inline-block;
  background: $blue;
  font: normal normal 600 16px/16px rooney-sans, sans-serif;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 10px;
  border: none;
  color: white;
  text-decoration: none;
  transition: all .15s ease-in-out;
  top: 0;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;

  &.small {
    padding: .6em .8em;
    font-size: 14px;
  }

  &.large {
    font-size: 1.1rem;
    padding: 15px 40px 17px 20px;
  }

  &.super {
    padding: 20px 55px 20px 20px;
    font-size: 1.4rem;
  }

  // Add the little '>' arrow at the end of the button
  &.large:after,
  &.super:after {
    content: '';
    width: 5px;
    height: 5px;
    border-top: 2px solid white;
    padding: .7em 2em .5em 1.5em;
    border-right: 2px solid white;
    display: inline-block;
    transform: rotate(45deg);
    top: 22.5px;
    right: 20px;
    position: absolute;
    padding: 0;
  }
  &.super:after {
    top: 28.5px;
    right: 28px;
  }

  // Full-width
  &.full {
    width: 100%;
    text-align: center;
  }

  &.blue {
    /* Already is :) */
    color: white !important;
    &:hover { background: $blue-dark }
  }

  // &.rounded {
  //   border: none;
  //   border-radius: 100px;

  //   &.small { padding: .6em 1.25em }
  // }

  &.teal {
    background: $teal;
    border-color: $teal-dark;
    &:hover {
      background: $teal-dark;
      border-color: darken($teal-dark, 7%);
    }
  }

  &.red {
    background: $red;
    border-color: $red-dark;
    &:hover {
      background: darken($red, 7%);
      border-color: darken($red-dark, 7%);
    }
  }

  &.black {
    background: $gray-dark;
    border-color: $black;
    &:hover {
      background: $black;
      border-color: $black;
    }
  }

	&.white {
		color: $gray-dark;
		background: white;
    border: 1px solid white;
    &:hover { background: white }

    &.outline {
      border: 1px solid rgba($gray, .3);
      &:not(.fake):hover { border-color: darken(rgba($gray, .6), 7%) }
    }	
	}
}


// Put buttons next to each other, like on academy cards
.field.is-grouped * { margin-right: .75em }